// import ERC721 from '../../src/Abi/erc721.json'
// import ERC1155 from '../../src/Abi/erc1155.json'
// import TRADE from '../../src/Abi/market.json'
// import profile from '../../assets/images/avatar.png'
var EnvName = 'demossl';
var key = {};
key.ONEDAYINSECONDS = 0
key.env = EnvName
key.BLOCKS_PER_YEAR = 0
key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
key.MOBILE = /^\d{10}$/
key.NumOnly = /^\d+$/
key.PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
key.notSpecil = /^[a-zA-Z0-9]+$/
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/
key.IPFS = 'https://ipfs.io/ipfs/'
key.DecimalAlloweddigits = /^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?|[0-9]+)$/
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g
if (EnvName === "demo") {
    key.KEY = 'MNBVCZX1234)(*'
    key.FRONT_URL = 'http://nftdemo.bimaticz.com/'
    key.BACK_URL = 'http://192.53.121.26:7017/v1/front'
    key.ADMIN_URL = 'http://192.53.121.26:7017/v1/admin'
    key.IMG_URL = 'http://192.53.121.26:7017'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    // key.profile         =    profile
    key.TradeContract = '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
    // key.TradeContract   =   '0x3bE52bd3A97ac6Ba20F1482e58d9694B3E15Fb05'.toLowerCase()
    key.ERC721 = '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase()
    key.ERC1155 = '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    key.erc20Address = '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    key.RPC_URL = "https://api.avax-test.network/ext/bc/C/rpc"
    key.chain_Id_List = [97, 43113]
    key.BNBCHAIN = 97
    key.ETHCHAIN = 43113
    // key.RPC_URL         =   "https://endpoints.omniatech.io/v1/bsc/testnet/public"
    key.CHAIN_ID = 43113
    key.COIN_NAME = "BNB"
    key.Block_URL = {
        ETH: "https://testnet.snowtrace.io/",
        BNB: "https://testnet.bscscan.com/"
    }
}
else if (EnvName === "stage") {

}
else if (EnvName === "production") {
    key.KEY = 'MNBVCZX1234)(*'
    key.FRONT_URL = 'https://naifty.io'
    key.BACK_URL = 'https://api.naifty.io/v1/front'
    key.ADMIN_URL = 'https://api.naifty.io/v1/admin'
    key.IMG_URL = 'https://api.naifty.io'
    // key.profile         =    profile
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract = '0xC9Cfa2dd1Ed7Bc29C7d9B990b2DE7Ee630478ec4'.toLowerCase()
    key.ERC721 = '0x99d344133e46720e286f9c5527d2cc2e304953d8'.toLowerCase()
    key.ERC1155 = '0x0b6369b506e811df30ab7c0ce0ae00b8690b76be'.toLowerCase()
    key.erc20Address = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'.toLowerCase()
    key.RPC_URL = "https://endpoints.omniatech.io/v1/eth/mainnet/public"
    // key.RPC_URL         =   "https://mainnet.infura.io/v3/be5bdb23c61c4b92b174cb569e57f7b2"
    key.CHAIN_ID = 1
    key.chain_Id_List = [56, 1]
    key.BNBCHAIN = 56
    key.ETHCHAIN = 1
    key.COIN_NAME = "ETH"
    key.Block_URL = {
        ETH: "https://etherscan.io/",
        BNB: "https://bscscan.com/"
    }
}
else if (EnvName === "demossl") {
    key.KEY = 'MNBVCZX1234)(*'
    key.FRONT_URL = 'https://homecube-build-testing.pages.dev/'
    key.BACK_URL = 'https://backend-homecubes.maticz.in/v1/front'
    key.ADMIN_URL = 'https://backend-homecubes.maticz.in/v1/admin'
    key.IMG_URL = 'https://backend-homecubes.maticz.in'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    // key.profile         =    profile
    // key.TradeContract   =   '0xD370f5D497cc4b2344a9936f24E47284693d96D5'.toLowerCase() // bnb
    key.TradeContract = '0x19D4c0f9155C6517580f850D4D097AF0448a1B39'.toLowerCase() //sepolia
    // key.TradeContract = '0xD87ddfE179fE3e2Aea84041118a7E6C7EC975fe3'.toLowerCase() //sepolia
    key.ERC721 = '0x575cd9E4099A38B536673F557063f9A546870d11'.toLowerCase() // sepolia
    // key.ERC721          =   '0x6fbcF768b6E0cf9ED6Cc38ad41EDb939E925deaf'.toLowerCase() //bnb
    key.ERC1155 = '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    key.erc20Address = '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    // key.RPC_URL         =   "https://data-seed-prebsc-2-s1.binance.org:8545/"
    key.chain_Id_List = [97, 11155111]
    key.BNBCHAIN = 97
    key.ETHCHAIN = 11155111
    key.RPC_URL = "https://ethereum-sepolia.publicnode.com"
    key.CHAIN_ID = 11155111
    key.COIN_NAME = "BNB"
    key.Block_URL = {
        ETH: "https://testnet.snowtrace.io/",
        BNB: "https://testnet.bscscan.com/"
    }
}
else {
    key.KEY = 'MNBVCZX1234)(*'
    key.FRONT_URL = 'http://localhost:3000'
    key.BACK_URL = 'http://localhost:3030/v1/front'
    key.ADMIN_URL = 'http://localhost:3030/v1/admin'
    key.IMG_URL = 'http://localhost:3030'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    // key.profile         =    profile
    // key.TradeContract   =   '0xD370f5D497cc4b2344a9936f24E47284693d96D5'.toLowerCase() // bnb
    // key.TradeContract = '0xD87ddfE179fE3e2Aea84041118a7E6C7EC975fe3'.toLowerCase() //sepolia
    key.TradeContract = '0x19D4c0f9155C6517580f850D4D097AF0448a1B39'.toLowerCase() //sepolia
    key.ERC721 = '0x575cd9E4099A38B536673F557063f9A546870d11'.toLowerCase() // sepolia
    // key.ERC721          =   '0x6fbcF768b6E0cf9ED6Cc38ad41EDb939E925deaf'.toLowerCase() //bnb
    key.ERC1155 = '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    key.erc20Address = '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    // key.RPC_URL         =   "https://data-seed-prebsc-1-s1.binance.org:8545/"
    // key.RPC_URL         =   "https://api.avax-test.network/ext/bc/C/rpc"
    key.chain_Id_List = [97, 11155111]
    key.BNBCHAIN = 97
    key.ETHCHAIN = 11155111
    key.RPC_URL = "https://ethereum-sepolia.publicnode.com"
    key.CHAIN_ID = 11155111
    key.COIN_NAME = "BNB"
    key.Block_URL = {
        ETH: "https://testnet.snowtrace.io/",
        BNB: "https://testnet.bscscan.com/"
    }
}
export default key;
